<template>
  <section>
    <div class="container bg-dark px-3 py-4 my-5">
      <div class="container-md px-3 py-5 bg-light my-2 clickado-form-container">
        <h3 class="shadow border rounded pt-2 mb-5">Clickado Form</h3>
        <form
          class="clickado-form"
          v-if="html_store.html_string"
          v-on:submit.prevent="clickado"
          v-html="html_store.html_string"
        ></form>
        <p v-else>Building your form.</p>
      </div>
    </div>
    <button
      class="btn btn-secondary"
      type="button"
      name="button"
      v-on:click="download"
    >
      Download
    </button>
  </section>
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authentication";
import { useHtmlStore } from "@/stores/execution";
import { useTemplateStore } from "@/stores/templates";
import { useFormFieldStore } from "@/stores/formFields";

// Variables
const router = useRouter();
const authStore = useAuthStore();
const html_store = useHtmlStore();
const template_store = useTemplateStore();
const field_store = useFormFieldStore();

// Methods
html_store.getHtmlSting();
template_store.getTemplateList();
template_store.getActiveTemplate();
field_store.getOrganizationFields();
const clickado = (submitEvent) => {
  let myForm = submitEvent.target;
  const FormData = require("form-data");
  const excecuteForm = new FormData(myForm);

  html_store.clickado(excecuteForm);
};

const download = () => html_store.download();

onMounted(() => {
  if (!authStore.user_id) {
    router.push({ name: "Login" });
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.clickado-form-container {
  max-width: 720px;

  .clickado-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>

<!--
  The Global style is necessary for the content of the form.
  If not global, the styling is not applied to the form that is injected after
  the styling is applied to the component.
-->
<style lang="scss">
.clickado-form {
  .btn-primary {
    &:hover {
      background-color: transparent;
    }
  }
  label,
  .clickado-form-label {
    width: 100%;
    text-align: left;
  }
}
</style>
