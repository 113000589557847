// PINIA STORE handling the execution of CLICKADO

import axios from "axios";
import swal from "sweetalert";
import { defineStore } from "pinia";
import { useAuthStore } from "./authentication";
import { useTemplateStore } from "./templates";
import { useFormFieldStore } from "./formFields";

export const useHtmlStore = defineStore("html-forms", {
  state: () => {
    return {
      html_string: null,
    };
  },
  getters: {
    headers: () => {
      const authStore = useAuthStore();
      return {
        headers: { Authorization: authStore.token },
      };
    },

    hasTemplate: () => {
      const template_store = useTemplateStore();
      // active_template should return something if it exists (true)
      // or undefined if it does not exist (false)
      return template_store.active_template;
    },

    hasFields: () => {
      const field_store = useFormFieldStore();
      return field_store.organization_fields;
    },
  },
  actions: {
    getHtmlSting() {
      axios
        .get("/api/html", this.headers)
        .then((res) => {
          this.html_string = res.data;
        })
        .catch((err) => {
          console.error({
            status: err.response.status,
            message: err.response.data,
          });
        });
    },
    async clickado(excecuteForm) {
      // Assert that the user has at least an active template and one field.
      if (!this.hasTemplate || !this.hasFields) {
        swal({
          title: "Missing",
          text: "Your profile is missing an active template OR form fields. Go the the settings page to set-up your profile before trying to execute Clickado.",
          icon: "warning",
          button: true,
        });
        return;
      }

      // Convert form to object
      let myData = {};
      excecuteForm.forEach((value, key) => {
        myData[key] = value;
      });

      axios
        .post("/api/execute", myData, this.headers)
        .then((res) => {
          swal("Success!", res.data, "success");
        })
        .catch((err) => {
          console.error({
            status: err.response.status,
            message: err.response.data,
          });
        });
    },

    download() {
      axios
        .get("/api/execute/download", {
          responseType: "blob",
          headers: this.headers.headers,
        })
        .then((res) => {
          console.log(res);
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;

          const fileName =
            res.headers["content-disposition"].split("filename=")[1];

          fileLink.setAttribute("download", fileName);
          document.body.append(fileLink);
          fileLink.click();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            swal({
              title: "Not Found",
              text: "It seems that you haven't populated your active template yet. Please execute Clickado before trying to download the resulting file.",
              icon: "error",
              button: true,
            });
            return;
          } else {
            console.error({
              status: err.response.status,
              message: err.response.data,
            });
          }
        });
    },
  },
});
